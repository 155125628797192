import { BehaviorSubject } from "rxjs";

export interface IState {
  auth: boolean;
  token: string;
  username: string;
  role: string;
  order: {
    supplier: {};
    buyer: {};
    items: [];
    skuList: [];
    importPoCoo: "";
  };
  buysheet:{
    domain:"",
    buySheetID:"",
    buySheetName:"",
    addItemToBuysheet:{
      "items":{},
      "buysheetData":{}
      }
  };
  paymentMethods: [];
  packingMethods: [];
  systemDate: string,
  breadcrumb: {
    label: "",
    url: "",
  };
  unsavedStatus:{
    domain: "",
    isDirty: "",
    id:"",
  };
  imageDetails:[];
  notification: {};
}


export const store: BehaviorSubject<IState> = new BehaviorSubject({
  // Initial state

  auth: false,
  token: "no token",
  username: "",
  role: "",
  order: {
    supplier: {},
    buyer: {},
    items: [],
    skuList: [],
    importPoCoo: "",
  },
  buysheet:{ domain:"",
    buySheetID:"",
    buySheetName:"",
    addItemToBuysheet:{
      "items":{},
      "buysheetData":{}
      },
    }, 
  paymentMethods: [],
  packingMethods: [],
  systemDate: "",
  breadcrumb: {
    label: "",
    url: "",
  },
  unsavedStatus:{
    domain: "",
    isDirty: "",
    id:"",
  },
  imageDetails:[],
  notification: {}
});

export function setState(newState) {
  store.next({
    ...store.value,
    ...newState,
  });
}

const getStateObservable = () => store.asObservable();

export default getStateObservable;
