import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SharedState {
  resetStore: boolean;
  approvedOrderFromOrder: any;
  orderUpdatedForApproval: boolean;
  orderUpdatedForSpend: boolean;

}

const initialState: SharedState = {
  resetStore: false,
  approvedOrderFromOrder: '',
  orderUpdatedForApproval: false,
  orderUpdatedForSpend: false,
};

const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setResetStoreState: (state, action: PayloadAction<boolean>) => {
      state.resetStore = action.payload;
    },
    setApprovedOrderFromOrder: (state, action: PayloadAction<boolean>) => {
      state.approvedOrderFromOrder = action.payload;
    },
    setOrderUpdatedForApproval: (state, action: PayloadAction<boolean>) => {
      state.orderUpdatedForApproval = action.payload;
    },
    setOrderUpdatedForSpend: (state, action: PayloadAction<boolean>) => {
      state.orderUpdatedForSpend = action.payload;
    },
  },
});

export const { setResetStoreState, setApprovedOrderFromOrder, setOrderUpdatedForApproval, setOrderUpdatedForSpend } = sharedSlice.actions;

export default sharedSlice.reducer;