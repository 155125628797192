import getStateObservable from "./store";
import {
  setAuth,
  setToken,
  setUserName,
  setRole,
  setSupplier,
  setBuyer,
  setPackingMethod,
  setPaymentMethod,
  setItems,
  setImportPoCoo,
  setSystemDate,
  setBuysheet,
  setBreadcrumb,
  setUnsavedStatus,
  setSkus,
  setImageDetails
} from "./actions";
import {connectWebsocket } from "./websocketservice"

export default {
  getStateObservable,
  setAuth,
  setToken,
  setUserName,
  setRole,
  setSupplier,
  setBuyer,
  setPackingMethod,
  setPaymentMethod,
  setItems,
  setImportPoCoo,
  setSystemDate,
  setBuysheet,
  setBreadcrumb,
  setUnsavedStatus,
  setSkus,
  setImageDetails,
  connectWebsocket
};
