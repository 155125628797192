import { setState, store } from "../store";

/**
 * Method to set if authorization is true or false
 * @param auth
 */
export const setAuth = (auth: boolean): void => {
  setState({ auth });
};

/**
 * Method to set token
 * @param token
 */
export const setToken = (token: string): void => {
  setState({ token });
};
/**
 * Method to set username
 * @param username
 */
export const setUserName = (username: string): void => {
  setState({ username });
};

/**
 * Method to set role
 * @param role
 */
export const setRole = (role: string): void => {
  setState({ role });
};

/**
 * Method to set supplier
 * @param supplier
 */
export const setSupplier = (supplier: any): void => {
  setState({
    order: {
      supplier: {
        ...supplier,
      },
      buyer: {
        ...store.getValue().order.buyer,
      },
      importPoCoo: store.getValue().order.importPoCoo,
    },
    buysheet: {
      ...store.getValue().buysheet,
    },
  });
};

/**
 * Method to set buyer
 * @param buyer
 */
export const setBuyer = (buyer: any): void => {
  setState({
    order: {
      supplier: {
        ...store.getValue().order.supplier,
      },
      buyer: {
        //  ...store.getValue().order.buyer,
        ...buyer,
      },
      importPoCoo: store.getValue().order.importPoCoo,
    },
    buysheet: {
      ...store.getValue().buysheet,
    },
  });
};
/**
 * Method used to set Packaging method
 * @param setPackingMethod
 */
export const setPackingMethod = (packingMethods: string): void => {
  setState({ packingMethods });
};
/**
 * Method used to set Payment Method
 * @param paymentMethods
 */
export const setPaymentMethod = (paymentMethods: string): void => {
  setState({ paymentMethods });
};

/**
 * Method used to set Item
 * @param item
 */
export const setItems = (item: any): void => {
  console.log("-------------------setItems----------------", item);
  console.log("-------------------setItems ...items----------------", [
    ...item,
  ]);

  setState({
    order: {
      supplier: {
        ...store.getValue().order.supplier,
      },
      buyer: {
        ...store.getValue().order.buyer,
      },
      items: item.length == 0 ? store.getValue().order.items : [...item],
      importPoCoo: store.getValue().order.importPoCoo,
    },
    buysheet: {
      ...store.getValue().buysheet,
    },
  });
};

/**
 * Method to set Import POCOO in store
 * @param pocoo
 */
export const setImportPoCoo = (pocoo: any): void => {
  setState({
    order: {
      supplier: {
        ...store.getValue().order.supplier,
      },
      buyer: {
        ...store.getValue().order.buyer,
      },
      importPoCoo: pocoo,
    },
    buysheet: {
      ...store.getValue().buysheet,
    },
  });
};

/**
 * Method used to set buysheet object
 * @param buysheet Buysheet object
 */
export const setBuysheet = (buysheet: any): void => {
  console.log("-------------------setItems----------------", buysheet);
  console.log("-------------------setBuysheet----------------", buysheet);

  setState({
    order: {
      supplier: {
        ...store.getValue().order.supplier,
      },
      buyer: {
        ...store.getValue().order.buyer,
      },
      items: [store.getValue().order.items],
      importPoCoo: store.getValue().order.importPoCoo,
    },
    buysheet: {
      ...buysheet,
    },
  });
};
export const setSystemDate = (systemDate: string): void => {
  setState({ systemDate });
};
export const setBreadcrumb = (breadcrumb: any): void => {
  setState({
    breadcrumb: {
      ...breadcrumb,
    },
  });
};
export const setUnsavedStatus = (unsavedStatus: any): void => {
  setState({
    unsavedStatus: {
      ...unsavedStatus,
    },
  });
};

/**
 * Method used to set specified list of SKU's
 * @param skus
 */
export const setSkus = (skus: any): void => {
  setState({
    order: {
      supplier: {
        ...store.getValue().order.supplier,
      },
      buyer: {
        ...store.getValue().order.buyer,
      },
      items: [store.getValue().order.items],
      skuList: [...skus],
      importPoCoo: store.getValue().order.importPoCoo,
    },
  });
};
/**
 * Method used to set image details received from bynder
 * @param imageDetails
 */
export const setImageDetails = (imageDetails: string): void => {
  setState({ imageDetails });
};
/**
 * Method used to set notification
 * @param notification 
 */
export const setNotification = (notification: any): void => {
  setState({ notification });
};