import { setImageDetails, setNotification } from "./actions";
import { WebPubSubClient } from "@azure/web-pubsub-client";

export async function connectWebsocket(
  token: any,
  websocketurl: any,
  subscriptionkey: any
) {
  let wsslink =
    websocketurl +
    "?subscription-key=" +
    subscriptionkey +
    "&access_token=" +
    token;
  const client = new WebPubSubClient(wsslink);
  await client.start();

  client.on("server-message", function (msg: any) {
    console.log(
      "+++++++++++++++++++++++++++testwebsocket++++++++++++++++++++",
      msg
    );
    if (
      msg &&
      msg.message &&
      msg.message.data &&
      msg.message.data.type === "NOTIFICATION"
    ) {
      setNotification(msg.message.data);
    } else {
      let imageDetails: any = [];
      imageDetails.push(JSON.parse(msg.message.data.message).messageDetails);
      setImageDetails(imageDetails);
    }
  });
}
