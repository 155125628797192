/**
 * This file defines a Redux slice for managing the state related to orders. 
 * It includes the initial state, action creators, and the reducer function for handling various order-related actions. 
 * The actions provided here can be dispatched to update the order-related state in the Redux store.
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OrderState {
  searchOrderResult: any;
  searchOrderCriteria: any;
  orderDetails: any;
  selectedOrder: any;
  filterInput: any;
  sortedData: any;
  additionalAttribute: any;
  searchRadioButton: any;
  selectedSku: any;
  filteredSku: any;
  sortedSku : any;
  filteredItem : any;
  checkedItem : any;
  editedOrder : any;
  editedSku : any;
}

const initialState: OrderState = {
  searchOrderResult: '',
  searchOrderCriteria: '',
  orderDetails: '',
  selectedOrder:'',
  filterInput:'',
  sortedData:'',
  additionalAttribute:'',
  searchRadioButton:'',
  selectedSku:'',
  filteredSku:'',
  sortedSku:'',
  filteredItem:'',
  checkedItem:'',
  editedOrder:'',
  editedSku: '',
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setSearchOrderResult: (state, action: PayloadAction<any>) => {
      state.searchOrderResult = action.payload;
    },
    setSearchOrderCriteria: (state, action: PayloadAction<any>) => {
        state.searchOrderCriteria = action.payload;
    },
    setOrderDetails: (state, action: PayloadAction<any>) => {
        state.orderDetails = action.payload;
    },
    setSelectedOrder: (state, action: PayloadAction<any>) => {
      state.selectedOrder = action.payload;
    },
    setFilterInput: (state, action: PayloadAction<any>) => {
      state.filterInput = action.payload;
    }, 
    setSortedData: (state, action: PayloadAction<any>) => {
      state.sortedData = action.payload;
    },
    setAdditionalAttribute: (state, action: PayloadAction<any>) => {
      state.additionalAttribute = action.payload;
    },
    setsearchRadioButton: (state, action: PayloadAction<any>) => {
      state.searchRadioButton = action.payload;
    },
    setSelectedSku: (state, action: PayloadAction<any>) => {
      state.selectedSku = action.payload;
    },
    setFilteredSku: (state, action: PayloadAction<any>) => {
      state.filteredSku = action.payload;
    }, 
    setSortedSku: (state, action: PayloadAction<any>) => {
      state.sortedSku = action.payload;
    },
    setFilteredItem: (state, action: PayloadAction<any>) => {
      state.filteredItem = action.payload;
    },
    setcheckedItem: (state, action: PayloadAction<any>) => {
      state.checkedItem = action.payload;
    },
    setEditedOrder: (state, action: PayloadAction<any>) => {
      state.editedOrder = action.payload;
    },
    setEditedSku: (state, action: PayloadAction<any>) => {
      state.editedSku = action.payload;
    },
    resetOrderState: (state) => {
      state = initialState;
    },      
  },
});

export const {
  setSearchOrderResult,
  setSearchOrderCriteria,
  setOrderDetails,
  setSelectedOrder,
  setFilterInput,
  setSortedData,
  setAdditionalAttribute,
  setsearchRadioButton,
  setSelectedSku,
  setFilteredSku,
  setSortedSku,
  resetOrderState,
  setFilteredItem,
  setcheckedItem,
  setEditedOrder,
  setEditedSku
} = orderSlice.actions;

export default orderSlice.reducer;