import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ItemState {
  searchItem: any;
  otherItemData: string;
}

const initialState: ItemState = {
    searchItem: '',
    otherItemData: '',
};

const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    setItemSearch: (state, action: PayloadAction<string>) => {
      state.searchItem = action.payload;
    },
  },
});

export const { setItemSearch } = itemSlice.actions;

export default itemSlice.reducer;