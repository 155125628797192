import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BuysheetState {
    filterInput: any;
    sortInput: any;
    viewInput: any;
}

const initialState: BuysheetState = { 
    filterInput: '',
    sortInput: '',
    viewInput: '',
}

const BuysheetSlice = createSlice({
    name: 'buysheet',
    initialState,
    reducers: {
      setBuysheetFilterInput: (state, action: PayloadAction<any>) => {
        state.filterInput = action.payload;
      },
      setBuysheetSortInput: (state, action: PayloadAction<any>) => {
          state.sortInput = action.payload;
      },
      setBuysheetViewInput: (state, action: PayloadAction<any>) => {
        state.viewInput = action.payload;
      },
      resetBuysheetState: (state) => {
        state = initialState;
      }, 
    }
});

export const {
    setBuysheetFilterInput,
    setBuysheetSortInput,
    setBuysheetViewInput,
    resetBuysheetState
  } = BuysheetSlice.actions;
  
  export default BuysheetSlice.reducer;