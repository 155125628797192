/**
 * This file configures the Redux store using the Redux Toolkit.
 * It combines multiple reducers, each responsible for managing a specific slice of the application state.
 */
import { configureStore } from '@reduxjs/toolkit';
import orderReducer from './order-slice';
import itemReducer from './item-slice';
import sharedReducer from './shared-slice';
import approvalReducer from './approval-slice';
import spendReducer from './spend-slice';
import buysheetReducer from './buysheet-slice';

const store = configureStore({
  reducer: {
    order: orderReducer,
    item: itemReducer,
    approval: approvalReducer,
    spend: spendReducer,
    shared: sharedReducer,
    buysheet: buysheetReducer
  },
});

export default store;