/**
 * This file defines a Redux slice for managing the state related to approval. 
 * It includes the initial state, action creators, and the reducer function for handling various approval-related actions. 
 * The actions provided here can be dispatched to update the approval-related state in the Redux store.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ApprovalState {
  selectedSubmittedData: any;
  filteredSubmittedData: any;
  sortedSubmittedData: any;
  submittedOrder: any;
}

const initialState: ApprovalState = {
  selectedSubmittedData: '',
  filteredSubmittedData: '',
  sortedSubmittedData: '',
  submittedOrder: '',
};

const approvalSlice = createSlice({
  name: "approval",
  initialState,
  reducers: {
    setSelectedSubmittedData: (state, action: PayloadAction<any>) => {
      state.selectedSubmittedData = action.payload;
    },
    setFilteredSubmittedData: (state, action: PayloadAction<any>) => {
      state.filteredSubmittedData = action.payload;
    },
    setSortedSubmittedData: (state, action: PayloadAction<any>) => {
      state.sortedSubmittedData = action.payload;
    },
    setSubmittedOrder: (state, action: PayloadAction<any>) => {
      state.submittedOrder = action.payload;
    },
  },
});

export const {
  setSelectedSubmittedData,
  setFilteredSubmittedData,
  setSortedSubmittedData,
  setSubmittedOrder
} = approvalSlice.actions;

export default approvalSlice.reducer;
